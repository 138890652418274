import { A } from '@ember/array';
import { setProperties } from '@ember/object';
import Evented from '@ember/object/evented';
import Service, { inject as service } from '@ember/service';
import { isEmpty, isNone, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { dropTask, task } from 'ember-concurrency';
import RSVP from 'rsvp';

import config from 'later/config/environment';
import { FetchError } from 'later/errors/fetch';
import { ErrorSeverity } from 'later/services/errors';
import { TRIAL_TYPES } from 'later/utils/constants';
import { SegmentEventTypes } from 'later/utils/constants/segment-events';
import { getCookie } from 'later/utils/cookie';
import { fetch } from 'later/utils/fetch';
import isUniqueEmailDomain from 'later/utils/is-unique-email-domain';
import loadScript from 'later/utils/load-script';
import objectPromiseProxy from 'later/utils/object-promise-proxy';
import computeProofOfWork from 'later/utils/proof-of-work-hash';
import redirect from 'shared/utils/redirect';

import type SessionService from './-private/session';
import type EventsService from './events';
import type MutableArray from '@ember/array/mutable';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type DeviceModel from 'later/models/device';
import type DiscoveryListTaskModel from 'later/models/discovery-list-task';
import type GroupModel from 'later/models/group';
import type MembershipModel from 'later/models/membership';
import type SocialIdentityModel from 'later/models/social-identity';
import type SocialProfileModel from 'later/models/social-profile';
import type SubscriptionPlanModel from 'later/models/subscription-plan';
import type UserModel from 'later/models/user';
import type AlertsService from 'later/services/alerts';
import type CacheService from 'later/services/cache';
import type ErrorsService from 'later/services/errors';
import type LaterConfigService from 'later/services/later-config';
import type OnboardingService from 'later/services/onboarding';
import type PaymentService from 'later/services/payment';
import type ProgressBarService from 'later/services/progress-bar';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type SubscriptionsService from 'later/services/subscriptions';
import type { Maybe, UntypedService, VoidTaskType } from 'shared/types';

interface UserParams {
  name: string;
  email: string;
  password: string;
  timeZone?: string;
}

interface AccountParams {
  bestDescribes?: string;
  gdprCompliant: boolean;
  gdprComplianceTimestamp: number | null;
}

interface OneTimePasswordResponse {
  otp_required: boolean;
}

export default class AuthService extends Service.extend(Evented) {
  @service declare alerts: AlertsService;
  @service declare cache: CacheService;
  @service declare errors: ErrorsService;
  @service declare faye: UntypedService; // only place faye is mounted
  @service declare events: EventsService;
  @service declare intl: IntlService;
  @service declare laterConfig: LaterConfigService;
  @service declare localStorageManager: UntypedService;
  @service declare locale: UntypedService;
  @service declare onboarding: OnboardingService;
  @service declare payment: PaymentService;
  @service declare progressBar: ProgressBarService;
  @service declare router: RouterService;
  @service declare segment: UntypedService;
  @service declare segmentEvents: UntypedService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare session: SessionService;
  @service declare store: Store;
  @service declare subscriptions: SubscriptionsService;
  @service declare tiktok: UntypedService;
  @service declare userConfig: UntypedService;

  @tracked declare currentAccount: AccountModel;
  @tracked declare currentUserModel: UserModel; // reference to model in store; rather than loaded POJO
  @tracked private declare _currentGroup: GroupModel;
  @tracked currentFirstDay: number | null = null;
  @tracked discoveryListTasks: DiscoveryListTaskModel[] = [];
  @tracked isSetupComplete = false;
  @tracked isSignedUpSegmentEventSent = false;
  @tracked socialProfile: Maybe<SocialProfileModel> = null;
  @tracked twoFactorEnabled = false;
  @tracked localStorageItemsToClear = [
    'lastSearchRepost',
    'last_analytics_id',
    'last_group_id',
    'last_linkinbio_id',
    'recent_ig_analytics',
    'stories-wizard',
    'onboard-wizard',
    'last_conversations_profile_id',
    this.redirectCacheKey
  ];

  get redirectCacheKey(): string {
    return this.session.redirectCacheKey;
  }

  get currentGroup(): GroupModel {
    return this._currentGroup;
  }

  get currentSocialProfile(): SocialProfileModel {
    if (this.socialProfile) {
      return this.socialProfile;
    }

    const { cachedProfileIds } = this.selectedSocialProfiles;
    const groupProfiles = this.currentGroup?.socialProfiles ?? A([]);

    const cachedProfile = groupProfiles.find((profile) => cachedProfileIds.includes(profile.id));
    if (cachedProfile) {
      return cachedProfile;
    }

    const firstProfileInGroup = groupProfiles.get('firstObject');
    if (firstProfileInGroup) {
      return firstProfileInGroup;
    }

    const fallbackProfile = this.#getFallbackSocialProfile();
    if (fallbackProfile) {
      return fallbackProfile;
    }
    return this.store.createRecord('social-profile');
  }

  get groups(): MutableArray<GroupModel> {
    const groups = this.store.peekAll('group');
    if (!isNone(groups)) {
      this.#updateKeenReadKeys(groups);
    }
    return groups;
  }

  get hasDevices(): boolean {
    return Number(this.devices.length) > 0;
  }

  get isNewAccount(): boolean {
    return this.currentUserModel.isNewSignUp;
  }

  get lastGroup(): GroupModel | undefined {
    if (isNone(this.currentGroup)) {
      return this.groups.filterBy('id').sortBy('socialProfiles.length').get('lastObject');
    }
    return this.currentGroup;
  }

  get devices(): MutableArray<DeviceModel> {
    return this.store.peekAll('device');
  }

  get isKeycloak(): boolean {
    return this.laterConfig.keycloakSSOEnabled;
  }

  get keycloakRealm(): string {
    return config.APP.keycloakRealm;
  }

  get socialProfiles(): MutableArray<SocialProfileModel> {
    return this.store.peekAll('social-profile');
  }

  get socialSets(): MutableArray<SocialIdentityModel> {
    return this.store.peekAll('social-identity');
  }

  get users(): UserModel[] {
    const users = this.store.peekAll('user');
    return users.filter((user) => user.memberships.filter((membership: MembershipModel) => !membership.isContributor));
  }

  async createPartnerstackCustomer(user: UserModel, accountId?: string, partnerKey?: string): Promise<void> {
    if (partnerKey) {
      const base64DecodedPartnerKey = atob(partnerKey);
      const base64EncodedAuthorization = btoa(
        `${config.APP.partnerstackPublicKey}:${config.APP.partnerstackSecretKey}`
      );
      const body = {
        partner_key: base64DecodedPartnerKey,
        key: accountId,
        email: user.email,
        name: user.name,
        meta: {}
      };
      try {
        await fetch('https://api.partnerstack.com/v1/customers', {
          method: 'POST',
          headers: {
            Authorization: `Basic ${base64EncodedAuthorization}`
          },
          body
        });
      } catch (error) {
        const sanitizedErrorData = {
          error,
          body: Object.assign({}, { key: 'no key', name: 'no name' }, body)
        };
        this.errors.log('Failed to create Partnerstack Customer', sanitizedErrorData, ErrorSeverity.Info);
      }
    }
  }

  createUser = dropTask(
    async ({ name, email, password }: UserParams, accountParams: AccountParams, plan?: SubscriptionPlanModel) => {
      const { authenticityToken } = this.laterConfig;
      // call this a timestamp, but it's the counter. Mostly to confuse any looky-loos -iMack May 2022
      const timestamp = computeProofOfWork(email, password, authenticityToken);

      const client_id = await this.segment.getClientId();
      const session_id = await this.segment.getSessionId();
      const session_number = await this.segment.getSessionNumber();

      // Note: Google script for recaptcha is loaded in the signup-form and signup component
      // https://developers.google.com/recaptcha/docs/v3
      const recaptcha_token = await grecaptcha?.execute(config.APP.googleRecaptchaSiteKey, { action: 'submit' });

      const usersResponse = await fetch('/users.json', {
        method: 'POST',
        body: {
          authenticity_token: authenticityToken,
          timestamp,
          recaptcha_token,
          user: {
            name,
            email,
            password,
            password_confirmation: password,
            google_client_id: client_id,
            google_session_id: session_id,
            google_session_number: session_number,
            utm_string: this.getUTMString()
          }
        }
      });

      if (!usersResponse.token) {
        if (usersResponse.error) {
          throw new Error(usersResponse.error);
        }
        return;
      }

      const { queryParams } = this.router.currentRoute;
      if (plan?.planType) {
        const planUrl = this.router.urlFor('plans.plan', plan.planType, { queryParams });
        this.session.setRedirectUrl(planUrl);
      }

      if (!this.isKeycloak || this.delayKeycloakLogin) {
        const user = await this.setup(accountParams);
        if (!user) {
          return;
        }
        await this.session.authenticate('authenticator:custom', email);
      } else {
        this.router.transitionTo('user.signup.welcome', { queryParams });
      }

      return;
    }
  );

  login(identification: string, password: string, otp_attempt: string, clearPassword: () => void): Promise<void> {
    return new RSVP.Promise(async (resolve, reject) => {
      try {
        if (isEmpty(password)) {
          // don't want to send extra requests to backend; as these can be throttled -iMack
          this.alerts.warning(this.intl.t('alerts.authorization.sign_in_form.password_placeholder'), {
            sticky: false,
            preventDuplicates: true
          });
          return reject();
        }
        await this.session.authenticate('authenticator:devise', identification, password, otp_attempt);
        resolve();
      } catch (response) {
        if (typeof response === 'string') {
          // This appears to only happen locally. Adding logging to confirm this is the case.
          // It seems to happen when the backend believes we are logged in, but the frontend does not.
          // Explicitly logging out seems to fix the issue.
          this.errors.log('Login Failed with string as error', { response }, ErrorSeverity.Info);
          this.logout.perform();
          return reject();
        }

        const type = response.headers.get('content-type');

        if (response.status === 429) {
          // client is being throttled by b/e
          this.alerts.warning(this.intl.t('alerts.authorization.sign_in_form.throttled'));
        } else if (response.status === 302) {
          // login is giving a redirect. Special case for Canva logins. Just follow it -iMack May 2023
          const responseBody = await response.json();
          redirect(responseBody.location);
        } else if (type.includes('application/json')) {
          const error = await response.json();
          if (this.twoFactorEnabled) {
            if (otp_attempt === '') {
              this.alerts.warning(this.intl.t('alerts.authorization.sign_in_form.blank_code'));
            } else {
              this.alerts.warning(this.intl.t('alerts.authorization.sign_in_form.wrong_code'));
            }
          } else if (error?.error) {
            this.alerts.warning(error.error, { sticky: false });
            clearPassword();
          } else if (error.errors) {
            const otpRequired = error.errors.find(
              (error: string | OneTimePasswordResponse) => typeof error !== 'string' && error.otp_required
            );
            if (otpRequired) {
              this.twoFactorEnabled = true;
            } else {
              this.alerts.warning(error.errors[0], { sticky: false });
              clearPassword();
            }
          }
        } else if (response.status === 503 && type.includes('text/html')) {
          // client is seeing the cloudflare page or a maintenance, need to refresh page
          redirect('/');
        }
        reject();
      }
    });
  }

  async setup(accountParams?: Pick<AccountParams, keyof AccountParams>): Promise<UserModel | void> {
    const urlParams = new URLSearchParams(this.getUTMString());

    if (this.isSetupComplete) {
      return this.currentUserModel;
    }
    try {
      this.progressBar.start('body');
      const response = await fetch('/api/v2/users/me');
      const expirationTime = 14 * 24 * 60 * 60;
      if (this.session.store) {
        this.session.store.cookieExpirationTime = expirationTime;
      }

      const normalizedResponse = this.store.normalize('user', response.user);
      const user = this.store.push(normalizedResponse) as unknown as UserModel;
      const userId = user.id;
      const userAccountIdString = `${user.belongsTo('account').id()}`;

      const promises = [];
      this.store.findAll('media-item');
      promises.push(this.store.query('account', { userId })); // accounts second item index 0
      promises.push(this.store.findAll('discovery-list-task')); // discoveryListTask third item index 1
      promises.push(this.store.findAll('social-identity'));
      promises.push(this.subscriptions.reload());

      const resolvedPromises = await Promise.allSettled(promises);
      const values = resolvedPromises.map((resolvedPromise) => {
        if (resolvedPromise.status === 'fulfilled') {
          return resolvedPromise.value;
        }
        return undefined;
      });

      // TODO(TS): can I get ride of as unknown here (requires better promises or type guard)
      const accounts = values[0] as unknown as AccountModel[];
      const discoveryListTask = values[1] as unknown as DiscoveryListTaskModel[];

      const currentAccount = accounts.findBy('id', userAccountIdString) as any;

      this.#initFacebook(this.laterConfig.facebookAppId);

      const metaAccountParams = this.getMetaConversionParams(urlParams);
      if ((accountParams || metaAccountParams) && currentAccount) {
        setProperties(currentAccount, { ...accountParams, ...metaAccountParams } as Pick<
          AccountModel,
          keyof AccountModel
        >);
        try {
          await currentAccount.save();
        } catch (error) {
          this.errors.log('Failed to save properties to account', error, ErrorSeverity.Info);
        }
      }

      this.currentUserModel = user;
      this.currentAccount = currentAccount || null;
      this.discoveryListTasks = discoveryListTask;
      this.registerUser(user);

      await this.retrieveCurrentInfluencer.perform();

      this.trigger('authSetupFinished');
      this.events.trigger('auth:setup-complete', { user, account: this.currentAccount });

      await this.locale.setupIntl();

      await this.segment.identify.perform(user.id, currentAccount?.id);
      this.segment.updateGoogleClientId();

      this.tiktok.updateTiktokClickId();
      this.progressBar.done('body');
      this.isSetupComplete = true;
      return this.currentUserModel;
    } catch (error) {
      if (error instanceof FetchError) {
        error.resolve.call(this, null, error.message);
      }
      return await this.logout.perform();
    }
  }

  async setSocialProfile(socialProfile?: SocialProfileModel): Promise<void> {
    if (!socialProfile) {
      return;
    }
    try {
      const resolvedProfile = await objectPromiseProxy(socialProfile);
      const group = await objectPromiseProxy(resolvedProfile.group);
      if (this.socialProfile?.id !== resolvedProfile.id) {
        this.socialProfile = resolvedProfile;
        this.setGroup(group);
        if (!this.selectedSocialProfiles.profileIds.includes(resolvedProfile.id)) {
          this.selectedSocialProfiles.updateCachedProfileIds([resolvedProfile.id]);
        }
      }
    } catch (error) {
      this.errors.log('Could not set social profile', {
        socialProfileId: socialProfile?.id || 'Invalid social profile'
      });
    }
  }

  setGroup(group: GroupModel): void {
    this._currentGroup = group;

    if (this.currentGroup?.contributor) {
      return;
    }

    if (isNone(this.currentSocialProfile)) {
      this.socialProfile = this.currentGroup?.socialProfiles.firstObject;
    }
  }

  async refresh(): Promise<void> {
    await Promise.all([this.currentAccount?.reload(), this.currentUserModel?.reload()]);
  }

  async registerUser(user: UserModel): Promise<void> {
    this.userConfig.setupUserConfig();
    if (!isEmpty(user.firstDay)) {
      this.currentFirstDay = user.firstDay || null;
    } else {
      this.currentFirstDay = 0;
    }
    this.faye.setupFaye(user);

    try {
      const { user: userTraits, account: accountTraits } = await this.segment.fetchUserAndAccountTraits.perform();
      this.errors.setUser({
        email: user.email,
        id: user.id,
        name: user.name,
        user: userTraits,
        account: accountTraits
      });
    } catch (error) {
      this.errors.log('Unable to link user and account properties with Datadog', error);
      this.errors.setUser({
        email: user.email,
        id: user.id,
        name: user.name
      });
    }
  }

  async handleNewAccount(): Promise<void> {
    if (!this.currentUserModel || !this.isNewAccount) {
      return;
    }

    const { email, referredById, tiktokClickId, id: user_id } = this.currentUserModel;
    const isNewAccountFromCheckout = this.cache.retrieve('isNewAccountFromCheckout');
    const urlParams = new URLSearchParams(this.getUTMString());
    const utmSource = urlParams.get('utm_source');
    const isCampaignSignup = this.router.currentRoute?.name === 'campaigns.campaign.signup';

    const payload = {
      email,
      initial_free_rollout: `r${this.subscriptions.subscriptionPlan?.rolloutVersion}`,
      is_unique_domain: isUniqueEmailDomain(email),
      is_business: this.store.peekAll('social-profile').any((sp) => sp.isBusiness),
      is_campaign_signup: isCampaignSignup,
      location: isNewAccountFromCheckout ? 'checkout' : '',
      onboard_flow: this.onboarding.onboardingTestFlow,
      signup_source: referredById ? 'Referral' : this.onboarding.signupSource,
      ttclid: tiktokClickId,
      utm_medium: urlParams.get('utm_medium'),
      utm_campaign: urlParams.get('utm_campaign'),
      utm_source: utmSource,
      feature_source: urlParams.get('feature_source'),
      gclid: urlParams.get('gclid'),
      wbraid: urlParams.get('wbraid'),
      gbraid: urlParams.get('gbraid'),
      fbp: getCookie('_fbp'),
      fbc: getCookie('_fbc')
    };
    if (this.isSignedUpSegmentEventSent !== true) {
      this.segmentEvents.trackWithGAIntegration('signed-up-for-account', payload);
    }
    if (this.onboarding.isSignupFromLinkinbio) {
      this.segment.track('signed-up-via-linkinbio-channel');
    }
    this.isSignedUpSegmentEventSent = true;
    this.onboarding.initOnboardingSteps();
    await this.createPartnerstackCustomer(
      this.currentUserModel,
      this.currentAccount?.id,
      this.router.currentRoute?.queryParams?.gspk
    );

    const autoTrialExcludedFlows =
      isNewAccountFromCheckout || utmSource === 'linkinbio' || utmSource === 'linkin.bio' || utmSource === 'mavrck';
    if (
      this.currentUserModel.isAccountOwner &&
      this.onboarding.isTrialStartOnAccountSignupABTest &&
      !autoTrialExcludedFlows
    ) {
      const stripePlanId = await this.subscriptions.getSubscriptionPlanStripeId('growth');
      if (stripePlanId) {
        await this.payment.startSourcelessTrial.perform(stripePlanId, TRIAL_TYPES.autoTrial);
      }
    }

    try {
      await fetch(`/api/v2/users/${user_id}`, {
        method: 'PATCH',
        body: {
          user_id,
          user: {
            time_zone: this.userConfig.timeZone,
            is_new_sign_up: false
          }
        }
      });
    } catch (error) {
      this.errors.log('Failed to update is_new_signup', error, ErrorSeverity.Info);
    }
  }

  get delayKeycloakLogin(): boolean {
    return this.laterConfig.delayKeycloakLogin;
  }

  clearLocalStorage(): void {
    this.localStorageItemsToClear.forEach((item) => this.cache.remove(item));
    this.#clearAnalytics();
  }

  logout = dropTask(async () => {
    try {
      await fetch('/users/sign_out');
      await this.session.invalidate();
      this.isSetupComplete = false;
    } catch (error) {
      if (error instanceof FetchError) {
        error.resolve.call(this, null, error.message);
      }
    }
  });

  isProfileInCurrentGroup(socialProfile?: SocialProfileModel): boolean {
    if (!socialProfile) {
      return false;
    }

    return this.currentGroup?.socialProfiles.includes(socialProfile);
  }

  getUTMString(): string {
    return window.location.search;
  }

  async #initFacebook(appId: string): Promise<void> {
    window.fbAsyncInit = () => {
      FB.init({
        appId,
        autoLogAppEvents: true,
        xfbml: false,
        version: `v${config.APP.facebookGraphVersion}`
      });
    };
    try {
      await loadScript('https://connect.facebook.net/en_US/sdk.js', { id: 'facebook-jssdk' });
    } catch (error) {
      this.errors.log('Failed to load FB SDK', error, ErrorSeverity.Info);
    }
  }

  trackSignIn(): void {
    this.segment.track(SegmentEventTypes.SignedInAccount);
  }

  private getMetaConversionParams(urlParams: URLSearchParams): Partial<AccountModel> | undefined {
    const fbpCookie = getCookie('_fbp');
    const fbcCookie = getCookie('_fbc');
    const gclid = urlParams.get('gclid');
    const wbraid = urlParams.get('wbraid') || urlParams.get('WBRAID');
    const gbraid = urlParams.get('gbraid') || urlParams.get('GBRAID');

    const metaCookies: Record<string, string> = {};

    if (fbpCookie) metaCookies.fbp = fbpCookie;
    if (fbcCookie) metaCookies.fbc = fbcCookie;
    if (gclid) metaCookies.gclid = gclid;
    if (wbraid) metaCookies.wbraid = wbraid;
    if (gbraid) metaCookies.gbraid = gbraid;

    return Object.keys(metaCookies).length > 0 ? metaCookies : undefined;
  }

  private retrieveCurrentInfluencer: VoidTaskType = task(async () => {
    const socialIdentities = this.store
      .peekAll('social-identity')
      .filter((identity) => isPresent(identity.id) && identity.id !== '0');
    if (socialIdentities.length > 0) {
      const currentInfluencerId = this.currentSocialProfile.socialIdentity.get('influencerId');
      if (currentInfluencerId) {
        await this.store.findRecord('influencer', currentInfluencerId);
      }
    }
  });

  #clearAnalytics(): void {
    this.localStorageManager.getContentsKeys().forEach((key: string) => {
      if (key.startsWith('ajs_')) {
        this.localStorageManager.removeItem(key);
      }
    });
  }

  #updateKeenReadKeys(groups: MutableArray<GroupModel>): void {
    if (this.subscriptions.isPaid) {
      groups.forEach((group) => {
        group.get('socialProfiles').forEach((socialProfile: SocialProfileModel) => {
          const isIgMissingKey =
            socialProfile.profileType === 'instagram' && socialProfile.linkinbioEnabled && !socialProfile.keenReadKey;
          const isTwitterMissingKey = socialProfile.profileType === 'twitter' && !socialProfile.keenReadKey;
          if (isIgMissingKey || isTwitterMissingKey) {
            socialProfile.upsertKeenReadKey();
          }
        });
      });
    }
  }

  /**
   * Get the oldest Instagram Profile or first available Social Profile if no IG Profiles exist.
   */
  #getFallbackSocialProfile(): SocialProfileModel | undefined {
    const instagramProfiles = this.currentAccount?.instagramProfiles;
    if (!isEmpty(instagramProfiles)) {
      return instagramProfiles.sortBy('createdTime')?.firstObject;
    }

    const socialProfiles = this.currentAccount?.socialProfiles;
    if (!isEmpty(socialProfiles)) {
      return socialProfiles.sortBy('createdTime')?.firstObject;
    }

    return;
  }
}

declare module '@ember/service' {
  interface Registry {
    auth: AuthService;
  }
}
