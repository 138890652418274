import { computed } from '@ember/object';
import { equal, mapBy, max, notEmpty, readOnly, sum } from '@ember/object/computed';
import { isEmpty, isNone, isPresent } from '@ember/utils';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { collectionAction, memberAction } from 'ember-api-actions';
import moment from 'moment';

import type LinkinbioPageModel from './linkinbio-page';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import type { TaxIdStatus } from 'checkout/types';
import type GroupModel from 'later/models/group';
import type SocialProfileModel from 'later/models/social-profile';
import type SubscriptionModel from 'later/models/subscription';
import type UserModel from 'later/models/user';

type AccountInvitePayload = { email: string; name: string };
type AccountInviteResponse = { id: string; email: string };
type AccountOverages = {
  social_profiles?: { [profile: string]: number };
  users: number;
  clusters: number;
};

type UsagesRemainingPayload = {
  feature: 'ai_credits';
};

type RawUsagesRemainingResponse = {
  usages_remaining?: number;
  errors?: string[];
};

type TaxIds = Record<'string', { status: TaxIdStatus }>;

type UpdateTaxIdPayload = { vat_number: string; vat_business: string };

export default class AccountModel extends Model {
  @hasMany('socialProfile', { async: true }) declare socialProfiles: AsyncHasMany<SocialProfileModel>;
  @belongsTo('user', { async: true, inverse: null }) declare owner: AsyncBelongsTo<UserModel>;
  @hasMany('user', { async: true, inverse: 'account' }) declare users: AsyncHasMany<UserModel>;
  @hasMany('group', { async: true }) declare groups: AsyncHasMany<GroupModel>;
  @hasMany('subscription', { async: true }) declare subscriptions: AsyncHasMany<SubscriptionModel>;

  @attr('number') declare postsLeft?: number;
  @attr('number') declare postsCreatedThisMonth?: number;
  @attr('number') declare streamsLeft?: number;
  @attr('number') declare maxPosts?: number;
  @attr('number') declare maxTwitterPosts?: number;
  @attr('number') declare bonusPosts?: number;
  @attr('boolean') declare hasActiveSubscription?: boolean;
  @attr('boolean', { defaultValue: false }) declare hasCustomPlan: boolean;
  @attr('boolean', { defaultValue: false }) declare isStripeCustomer: boolean;
  @attr('string') declare canonicalBillingEmail?: string;
  @attr('boolean', { defaultValue: false }) declare emailReceipts: boolean;
  @attr('string') declare additionalBillingInfo?: string;
  @attr('number') declare instagramPostsLeft?: number;
  @attr('number') declare facebookPostsLeft?: number;
  @attr('number') lastFailedChargeAt?: number;
  @attr('number') declare linkedinPostsLeft?: number;
  @attr('number') declare pinterestPostsLeft?: number;
  @attr('number') declare tiktokPostsLeft?: number;
  @attr('number') declare twitterPostsLeft?: number;
  @attr('number') declare maxImageFileSize: number;
  @attr('number') declare youtubePostsLeft?: number;
  @attr('number') declare threadsPostsLeft?: number;
  @attr('number') declare maxVideoFileSize?: number;
  @attr('boolean') declare stopTheWorld?: boolean;
  @attr('number') declare stopTheWorldTime?: number;
  @attr() upcomingOverages?: AccountOverages;
  @attr('boolean') declare hasActiveCancelledSubscription?: boolean;
  @attr('boolean', { defaultValue: false }) declare grandfatheredFree: boolean;
  @attr('string') declare experience?: string;
  @attr('string') declare socialMediaGoal?: string;
  @attr('string') declare goalWithLater?: string;
  @attr('string') declare selectedRecommendation?: string;
  @attr('string') declare bestDescribes?: string;
  @attr('string') declare bestDescribesOther?: string;
  @attr('string') declare selfDescribesCompanyName?: string;
  @attr('string') declare companySize?: string;
  @attr('string') declare teamSize?: string;
  @attr('string') declare reasonForUsingLater?: string;
  @attr('string') declare whatEncouragedSignup?: string;
  @attr('string') declare stripeCustomerId?: string;
  @attr('string') declare segment?: string;
  @attr('number') paymentMethodExpiresAt?: number;
  @attr('string') declare pricingVersion?: string;
  @attr('boolean') declare pricingV1Free?: boolean;
  @attr('string') declare betaFeatures?: string;
  @attr() experiments?: Record<string, string>;
  @attr() recentlyDisconnectedProfiles?: unknown[];
  @attr('number', { defaultValue: 1 }) declare includedUsers: number;
  @attr('number', { defaultValue: 1 }) declare includedSocialSets: number;
  @attr('number', { defaultValue: 0 }) declare additionalUsers: number;
  @attr('number', { defaultValue: 0 }) declare additionalSocialSets: number;
  @attr('number') declare subscriptionCreatedTime?: number;
  @attr('string', { defaultValue: '' }) declare subscriptionProviderName: string;
  @attr('number') declare trialExpiresTime?: number;
  @attr('boolean') declare gdprCompliant?: boolean;
  @attr('number') declare gdprComplianceTimestamp?: number;
  @attr('boolean', { defaultValue: false }) declare stripeAutoSalesTaxSupported: boolean;
  @attr() declare taxIds: TaxIds;
  @attr('number') declare numberOfSocialListeningSearches?: number;
  @attr('string') declare enterpriseStatus: 'active' | 'inactive' | null;
  @attr('string') declare gclid?: string;
  @attr('string') declare wbraid?: string;
  @attr('string') declare gbraid?: string;
  @attr('string') declare fbp?: string;
  @attr('string') declare fbc?: string;
  @attr('boolean') declare linkinbioPlanAccess?: boolean;

  //Authorizations
  @attr('boolean') declare canAccessTagged?: boolean;
  @attr('boolean') declare canAnalyticsEmail?: boolean;
  @attr('boolean') declare canAnalyticsPerformanceReport?: boolean;
  @attr('boolean') declare canApprovalFlow?: boolean;
  @attr('boolean') declare canAutoSchedule?: boolean;
  @attr('boolean') declare canBttp?: boolean;
  @attr('boolean') declare canBttpV2?: boolean;
  @attr('boolean') declare canCalendarNotes?: boolean;
  @attr('boolean') declare canCalendarPerformance?: boolean;
  @attr('boolean') declare canCampaignBoard?: boolean;
  @attr('boolean') declare canCaptionHashtags?: boolean;
  @attr('boolean') declare canCarousel?: boolean;
  @attr('boolean') declare canChangeMedia?: boolean;
  @attr('boolean') declare canChat?: boolean;
  @attr('boolean') declare canCluster?: boolean;
  @attr('boolean') declare canCollectHashtags?: boolean;
  @attr('boolean') declare canContactUs?: boolean;
  @attr('boolean') declare canContentPillars?: boolean;
  @attr('boolean') declare canContributors?: boolean;
  @attr('boolean') declare canConversations?: boolean;
  @attr('boolean') declare canCredentialsWizard?: boolean;
  @attr('boolean') declare canExportCsv?: boolean;
  @attr('boolean') declare canFacebookAnalytics?: boolean;
  @attr('boolean') declare canFacebookGroups?: boolean;
  @attr('boolean') declare canFacebookMentions?: boolean;
  @attr('boolean') declare canFacebookReels?: boolean;
  @attr('boolean') declare canFilterMediaType?: boolean;
  @attr('boolean') declare canHashtagAnalytics?: boolean;
  @attr('boolean') declare canHashtagTrends?: boolean;
  @attr('boolean') declare canHideAiFeatures?: boolean;
  @attr('boolean') declare canIgCarouselAutoPublish?: boolean;
  @attr('boolean') declare canIgProductTagging?: boolean;
  @attr('boolean') declare canImportMediaFromUrl?: boolean;
  @attr('boolean') declare canInfluencerOptIn?: boolean;
  @attr('boolean') declare canInstagramAutocompleteUsername?: boolean;
  @attr('boolean') declare canInstagramPaidAnalytics?: boolean;
  @attr('boolean') declare canInstagramReels?: boolean;
  @attr('boolean') declare canInstagramReelsAnalytics?: boolean;
  @attr('boolean') declare canInstagramStories?: boolean;
  @attr('boolean') declare canLabs?: boolean;
  @attr('boolean') declare canLibAnalyticsHeatmap?: boolean;
  @attr('boolean') declare canLinkedin?: boolean;
  @attr('boolean') declare canLinkinbio?: boolean;
  @attr('boolean') declare canLinkinbioBannerless?: boolean;
  @attr('boolean') declare canLinkinbioBasicThemes?: boolean;
  @attr('boolean') declare canLinkinbioButtonBackgroundColor?: boolean;
  @attr('boolean') declare canLinkinbioButtonShadow?: boolean;
  @attr('boolean') declare canLinkinbioButtonShape?: boolean;
  @attr('boolean') declare canLinkinbioButtonStyle?: boolean;
  @attr('boolean') declare canLinkinbioButtonTextColor?: boolean;
  @attr('boolean') declare canLinkinbioEarlyAccess?: boolean;
  @attr('boolean') declare canLinkinbioEmbeddable?: boolean;
  @attr('boolean') declare canLinkinbioImageUploadPageBackground?: boolean;
  @attr('boolean') declare canLinkinbioExtendedClickAnalytics?: boolean;
  @attr('boolean') declare canLinkinbioFeaturedMediaEmbeddedVideo?: boolean;
  @attr('boolean') declare canLinkinbioMailchimp?: boolean;
  @attr('boolean') declare canLinkinbioMultiLink?: boolean;
  @attr('boolean') declare canLinkinbioPageBackgroundColor?: boolean;
  @attr('boolean') declare canLinkinbioPageFont?: boolean;
  @attr('boolean') declare canLinkinbioPageTextColor?: boolean;
  @attr('boolean') declare canLinkinbioPremiumThemes?: boolean;
  @attr('boolean') declare canLinkinbioReels?: boolean;
  @attr('boolean') declare canLinkinbioSeo: boolean;
  @attr('boolean') declare canLinkinbioSocialLinkListBlock?: boolean;
  @attr('boolean') declare canLinkinbioTiktokPostSupport?: boolean;
  @attr('boolean') declare canLinkinbioUtm?: boolean;
  @attr('boolean') declare canLongerAnalytics?: boolean;
  @attr('boolean') declare canMentions?: boolean;
  @attr('boolean') declare canPinterestAnalytics?: boolean;
  @attr('boolean') declare canPinterestClickTracking?: boolean;
  @attr('boolean') declare canPricingV2?: boolean;
  @attr('boolean') declare canProfileCategorizationAnalytics?: boolean;
  @attr('boolean') declare canScheduleFirstComment?: boolean;
  @attr('boolean') declare canSearchByProfile?: boolean;
  @attr('boolean') declare canSearchInfluencers?: boolean;
  @attr('boolean') declare canSeePast?: boolean;
  @attr('boolean') declare canShareMediaKit?: boolean;
  @attr('boolean') declare canSharePlan?: boolean;
  @attr('boolean') declare canSmartHashtags?: boolean;
  @attr('boolean') declare canTagged?: boolean;
  @attr('boolean') declare canTagLocation?: boolean;
  @attr('boolean') declare canTagUsers?: boolean;
  @attr('boolean') declare canTextOnImages?: boolean;
  @attr('boolean') declare canThreadsAnalytics?: boolean;
  @attr('boolean') declare canTiktok?: boolean;
  @attr('boolean') declare canTiktokAnalytics?: boolean;
  @attr('boolean') declare canTiktokAutoPublish?: boolean;
  @attr('boolean') declare canTiktokBttp?: boolean;
  @attr('boolean') declare canTiktokLinkinbio?: boolean;
  @attr('boolean') declare canTrialPlan?: boolean;
  @attr('boolean') declare canTwitterAnalytics?: boolean;
  @attr('boolean') declare canTwitterClickTracking?: boolean;
  @attr('boolean') declare canUseNotificationCenter?: boolean;
  @attr('boolean') declare canUsePostActivityLog?: boolean;
  @attr('boolean') declare canVideo?: boolean;
  @attr('boolean') declare canVideoAutopublish?: boolean;
  @attr('boolean') declare canVideoCoverSelect?: boolean;
  @attr('boolean') declare canVideoProcessing?: boolean;
  @attr('boolean') declare canAdaChatbot?: boolean;
  @attr('boolean') declare canSocialListening?: boolean;

  @readOnly('canLibAnalyticsHeatmap') declare canAccessLibAnalyticsHeatmap: boolean;
  @readOnly('canLinkedin') declare canAccessLinkedin: boolean;
  @readOnly('canProfileCategorizationAnalytics') declare canAccessProfileCategorizationAnalytics: boolean;

  @attr('boolean', { defaultValue: true }) declare rolloutAdditionalAiCredits: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutAutoEnableBttp: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutAutomatedContentPlanning: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutBillingForms: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutContentPillars: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutCreatePostSelectMedia: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutDraftPosts: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutIgWithFbLoginOauth: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutInstagramReelsCover: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutLinkinbioFeaturedMediaLinkGeneration: boolean;
  @attr('boolean', { defaultValue: false }) declare rolloutLinkinbioLimitedInstagramPermissions: boolean;
  @attr('boolean', { defaultValue: false }) declare rolloutPastDueModal: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutPaymentMethods: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutTiktokBusinessApi: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutArchiveCampaigns: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutTwoFactor: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutSourcelessTrial: boolean;
  @attr('boolean', { defaultValue: false }) declare rolloutOcePhase1: boolean;
  @attr('boolean', { defaultValue: false }) declare rolloutOcePhaseTwo: boolean;
  @attr('boolean', { defaultValue: false }) declare rolloutOcePhaseThree: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutYoutubeShorts: boolean;
  @attr('boolean', { defaultValue: false }) declare rolloutEnterpriseAnalytics: boolean;
  @attr('boolean', { defaultValue: false }) declare rolloutSocialListening: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutMavelyIntegration: boolean;
  @attr('boolean', { defaultValue: false }) declare rolloutFbPostPerformance: boolean;
  @attr('boolean', { defaultValue: true }) declare rolloutEuVat: boolean;
  @attr('boolean', { defaultValue: false }) declare rolloutFutureInsights: boolean;

  @equal('pricingVersion', 'v1') declare isOnLegacyPlan: boolean;

  @mapBy('instagramProfiles', 'followedBy') declare instagramProfileFollowerCounts: number[];
  @max('instagramProfileFollowerCounts') declare followerCountMax: number;

  @mapBy('groups', 'socialIdentitiesCount') declare socialSetCounts: number[];
  @sum('socialSetCounts') declare socialSetCount: number;

  @notEmpty('facebookPostsLeft') declare hasLimitedFacebookPosts: boolean;
  @notEmpty('instagramPostsLeft') declare hasLimitedInstagramPosts: boolean;
  @notEmpty('linkedinPostsLeft') declare hasLimitedLinkedinPosts: boolean;
  @notEmpty('pinterestPostsLeft') declare hasLimitedPinterestPosts: boolean;
  @notEmpty('tiktokPostsLeft') declare hasLimitedTiktokPosts: boolean;
  @notEmpty('twitterPostsLeft') declare hasLimitedTwitterPosts: boolean;
  @notEmpty('youtubePostsLeft') declare hasLimitedYoutubePosts: boolean;
  @notEmpty('threadsPostsLeft') declare hasLimitedThreadsPosts: boolean;

  invite = memberAction<AccountInvitePayload, AccountInviteResponse>({
    path: 'invite',
    type: 'post'
  });

  updateTaxId = memberAction<UpdateTaxIdPayload, { success: boolean }>({
    path: 'tax_id',
    type: 'patch'
  });

  usagesRemaining = collectionAction<UsagesRemainingPayload, RawUsagesRemainingResponse>({
    path: 'usages_remaining',
    type: 'GET'
  });

  get stopTheWorldOrPastDue(): boolean {
    const latestSubscription = this.subscriptions.filterBy('active').sortBy('activeUntilTime').lastObject;
    return Boolean(this.stopTheWorld || (latestSubscription?.isPastDue && this.rolloutPastDueModal));
  }

  @computed('followerCountMax', 'instagramProfiles.[]')
  get followerCount(): number {
    if (this.get('instagramProfiles').length === 0) {
      return 0;
    }
    return this.followerCountMax;
  }

  @computed('socialProfiles.@each.hasLimitedPosts')
  get hasAnyPostsLimit(): boolean {
    return this.socialProfiles.any((item) => item.get('hasLimitedPosts'));
  }

  @computed('instagramPostsLeft', 'hasLimitedInstagramPosts')
  get hasInstagramPostsLeft(): boolean {
    return !this.hasLimitedInstagramPosts || (this.instagramPostsLeft ?? 0) > 0;
  }

  @computed('tiktokPostsLeft', 'hasLimitedTiktokPosts')
  get hasTiktokPostsLeft(): boolean {
    return !this.hasLimitedTiktokPosts || (this.tiktokPostsLeft ?? 0) > 0;
  }

  @computed('twitterPostsLeft', 'hasLimitedTwitterPosts')
  get hasTwitterPostsLeft(): boolean {
    return !this.hasLimitedTwitterPosts || (this.twitterPostsLeft ?? 0) > 0;
  }

  @computed('pinterestPostsLeft', 'hasLimitedPinterestPosts')
  get hasPinterestPostsLeft(): boolean {
    return !this.hasLimitedPinterestPosts || (this.pinterestPostsLeft ?? 0) > 0;
  }

  @computed('facebookPostsLeft', 'hasLimitedFacebookPosts')
  get hasFacebookPostsLeft(): boolean {
    return !this.hasLimitedFacebookPosts || (this.facebookPostsLeft ?? 0) > 0;
  }

  @computed('linkedinPostsLeft', 'hasLimitedLinkedinPosts')
  get hasLinkedinPostsLeft(): boolean {
    return !this.hasLimitedLinkedinPosts || (this.linkedinPostsLeft ?? 0) > 0;
  }

  @computed('youtubePostsLeft', 'hasLimitedYoutubePosts')
  get hasYoutubePostsLeft(): boolean {
    return !this.hasLimitedYoutubePosts || (this.youtubePostsLeft ?? 0) > 0;
  }

  @computed('threadsPostsLeft', 'hasLimitedThreadsPosts')
  get hasThreadsPostsLeft(): boolean {
    return !this.hasLimitedThreadsPosts || (this.threadsPostsLeft ?? 0) > 0;
  }

  @computed('groups.@each.defaultCluster')
  get defaultCluster(): GroupModel | undefined {
    return this.groups.findBy('defaultCluster');
  }

  @computed('socialProfiles.@each.isBusiness')
  get businessProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isBusiness');
  }

  @computed('businessProfiles')
  get hasBusinessProfile(): boolean {
    return !isEmpty(this.businessProfiles);
  }

  @computed('socialProfiles.@each.isCreator')
  get creatorProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isCreator');
  }

  @computed('creatorProfiles')
  get hasCreatorProfile(): boolean {
    return !isEmpty(this.creatorProfiles);
  }

  @computed('socialProfiles.@each.isProfessional')
  get professionalProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isProfessional');
  }

  @computed('professionalProfiles')
  get hasProfessionalProfile(): boolean {
    return !isEmpty(this.professionalProfiles);
  }

  @computed('socialProfiles.@each.isInstagram')
  get instagramProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isInstagram');
  }

  @computed('instagramProfiles')
  get hasInstagramProfile(): boolean {
    return !isEmpty(this.instagramProfiles);
  }

  @computed('socialProfiles.@each.isTwitter')
  get twitterProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isTwitter');
  }

  @computed('twitterProfiles')
  get hasTwitterProfile(): boolean {
    return !isEmpty(this.twitterProfiles);
  }

  @computed('socialProfiles.@each.isPinterest')
  get pinterestProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isPinterest');
  }

  @computed('pinterestProfiles')
  get hasPinterestProfile(): boolean {
    return !isEmpty(this.pinterestProfiles);
  }

  @computed('socialProfiles.@each.isFacebook')
  get facebookProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isFacebook');
  }

  @computed('facebookProfiles')
  get hasFacebookProfile(): boolean {
    return !isEmpty(this.facebookProfiles);
  }

  @computed('socialProfiles.@each.isLinkedin')
  get linkedinProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isLinkedin');
  }

  @computed('linkedinProfiles')
  get hasLinkedinProfile(): boolean {
    return !isEmpty(this.linkedinProfiles);
  }

  @computed('socialProfiles.@each.isTiktok')
  get tiktokProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isTiktok');
  }

  @computed('tiktokProfiles')
  get hasTiktokProfile(): boolean {
    return !isEmpty(this.tiktokProfiles);
  }

  @computed('socialProfiles.@each.isYoutube')
  get youtubeProfiles(): SocialProfileModel[] {
    return this.socialProfiles.filterBy('isYoutube');
  }

  @computed('youtubeProfiles')
  get hasYoutubeProfiles(): boolean {
    return !isEmpty(this.youtubeProfiles);
  }

  @computed('upcomingOverages')
  get instagramOverage(): number {
    return this.upcomingOverages?.social_profiles?.instagram ?? 0;
  }

  @computed('upcomingOverages')
  get setsOverages(): number {
    if (!this.upcomingOverages) {
      return 0;
    }
    const profiles = Object.values<number>(this.upcomingOverages?.social_profiles ?? {});
    return Math.max(...profiles);
  }

  @computed('instagramOverage')
  get hasInstagramOverage(): boolean {
    return this.instagramOverage > 0;
  }

  @computed('upcomingOverages')
  get twitterOverage(): number {
    return this.upcomingOverages?.social_profiles?.twitter ?? 0;
  }

  @computed('twitterOverage')
  get hasTwitterOverage(): boolean {
    return this.twitterOverage > 0;
  }

  @computed('upcomingOverages')
  get facebookOverage(): number {
    return this.upcomingOverages?.social_profiles?.facebook ?? 0;
  }

  @computed('facebookOverage')
  get hasFacebookOverage(): boolean {
    return this.facebookOverage > 0;
  }

  @computed('upcomingOverages')
  get pinterestOverage(): number {
    return this.upcomingOverages?.social_profiles?.pinterest ?? 0;
  }

  @computed('pinterestOverage')
  get hasPinterestOverage(): boolean {
    return this.pinterestOverage > 0;
  }

  @computed('upcomingOverages')
  get linkedinOverage(): number {
    return this.upcomingOverages?.social_profiles?.linkedin ?? 0;
  }

  @computed('linkedinOverage')
  get hasLinkedinOverage(): boolean {
    return this.linkedinOverage > 0;
  }

  get linkinbioPages(): LinkinbioPageModel[] {
    return this.groups
      .toArray()
      .map((group) => group.get('linkinbioPages').toArray())
      .flat()
      .filter(Boolean);
  }

  get hasOverageLinkinbioPages(): boolean {
    const createdLinkinbioPages = this.linkinbioPages.filter((page) => Boolean(page.get('id')));
    return Number(createdLinkinbioPages.length) > this.socialSetCount - this.socialProfilesOverage;
  }

  get linkinbioPagesOverage(): number {
    const createdLinkinbioPages = this.linkinbioPages.filter((page) => Boolean(page.get('id')));
    return Number(createdLinkinbioPages.length) - (this.socialSetCount - this.socialProfilesOverage);
  }

  @computed('upcomingOverages')
  get tiktokOverage(): number {
    return this.upcomingOverages?.social_profiles?.tiktok ?? 0;
  }

  @computed('tiktokOverage')
  get hasTiktokOverage(): boolean {
    return this.tiktokOverage > 0;
  }

  @computed('upcomingOverages')
  get youtubeOverage(): number {
    return this.upcomingOverages?.social_profiles?.youtube ?? 0;
  }

  @computed('youtubeOverage')
  get hasYoutubeOverage(): boolean {
    return this.youtubeOverage > 0;
  }

  get canAccessCaptionHashtagSuggestions(): boolean {
    return Boolean(this.canCaptionHashtags);
  }

  get canAccessInfluencerOptIn(): boolean {
    return Boolean(this.canInfluencerOptIn) && (!this.isOnLegacyPlan || Boolean(this.pricingV1Free));
  }

  @computed(
    'hasInstagramOverage',
    'hasTwitterOverage',
    'hasFacebookOverage',
    'hasPinterestOverage',
    'hasTiktokOverage',
    'hasLinkedinOverage',
    'hasYoutubeOverage'
  )
  get hasSocialProfileOverage(): boolean {
    return (
      this.hasInstagramOverage ||
      this.hasTwitterOverage ||
      this.hasFacebookOverage ||
      this.hasPinterestOverage ||
      this.hasLinkedinOverage ||
      this.hasTiktokOverage ||
      this.hasYoutubeOverage
    );
  }

  @computed(
    'pinterestOverage',
    'facebookOverage',
    'instagramOverage',
    'twitterOverage',
    'linkedinOverage',
    'tiktokOverage',
    'youtubeOverage'
  )
  get socialProfilesOverage(): number {
    return (
      this.pinterestOverage +
      this.facebookOverage +
      this.instagramOverage +
      this.twitterOverage +
      this.linkedinOverage +
      this.tiktokOverage +
      this.youtubeOverage
    );
  }

  @computed('upcomingOverages')
  get usersOverage(): number {
    return this.upcomingOverages?.users ?? 0;
  }

  @computed('usersOverage')
  get hasUsersOverage(): boolean {
    return this.usersOverage > 0;
  }

  @computed('upcomingOverages')
  get groupsOverage(): number {
    return this.upcomingOverages?.clusters ?? 0;
  }

  @computed('groupsOverage')
  get hasGroupsOverage(): boolean {
    return this.groupsOverage > 0;
  }

  @computed('hasSocialProfileOverage', 'hasUsersOverage', 'hasGroupsOverage')
  get hasPlanOverages(): boolean {
    return this.hasSocialProfileOverage || this.hasUsersOverage || this.hasGroupsOverage;
  }

  @computed('stopTheWorldTime')
  get withinStopTheWorldThreshold(): boolean {
    if (isNone(this.stopTheWorldTime) || this.stopTheWorldTime === 0) {
      return false;
    }

    const threeDays = moment(this.stopTheWorldTime * 1000).subtract(3, 'days');
    return threeDays.unix() < moment().unix();
  }

  @computed('hasPlanOverages', 'withinStopTheWorldThreshold')
  get shouldWarnOfPlanOverages(): boolean {
    return this.hasPlanOverages && this.withinStopTheWorldThreshold;
  }

  @computed('betaFeatures')
  get betaFeaturesArray(): string[] {
    if (!this.betaFeatures) {
      return [];
    }
    return this.betaFeatures.split(',');
  }

  @computed('betaFeaturesArray.[]')
  get hasIGAnalytcsV2Beta(): boolean {
    return this.betaFeaturesArray.includes('ig_analytics_v2');
  }

  @computed('socialProfiles.@each.linkinbioEnabled')
  get linkinbioEnabled(): boolean {
    return this.socialProfiles.any((profile) => profile.linkinbioEnabled);
  }

  @computed('socialProfiles.[]')
  get mainProfile(): SocialProfileModel | undefined {
    const sortedProfiles = this.socialProfiles.sortBy('followedBy').reverse();
    const igProfiles = isPresent(sortedProfiles) ? sortedProfiles.filterBy('isInstagram') : [];

    return isPresent(igProfiles) ? igProfiles.firstObject : sortedProfiles.firstObject;
  }

  @computed('socialProfiles.[]')
  get mainBusinessProfile(): SocialProfileModel | undefined {
    const sortedProfiles = this.socialProfiles.sortBy('followedBy').reverse();
    const igProfiles = isPresent(sortedProfiles) ? sortedProfiles.filterBy('isInstagram') : [];
    const businessProfiles = isPresent(igProfiles) ? sortedProfiles.filterBy('isBusiness') : [];

    return isPresent(businessProfiles) ? businessProfiles.firstObject : this.mainProfile;
  }

  @computed('isOnLegacyPlan')
  get canConnectTiktokApi(): boolean {
    return !this.isOnLegacyPlan;
  }

  get hasInvalidTaxId(): boolean {
    if (!this.taxIds) {
      return false;
    }
    return Object.values(this.taxIds).any((taxId) => taxId.status === 'unverified');
  }

  get hasValidTaxId(): boolean {
    if (!this.taxIds) {
      return false;
    }
    return Object.values(this.taxIds).any((taxId) => taxId.status === 'verified');
  }

  get trialExpiryMoment(): moment.Moment | null {
    return this.trialExpiresTime ? moment.unix(this.trialExpiresTime) : null;
  }

  get trialDaysRemaining(): number | null {
    // Round up days remaining to display accurate number.
    // i.e. There is always "1" day between today and tomorrow. Not "0.4"
    return this.trialExpiryMoment ? Math.ceil(this.trialExpiryMoment.diff(moment(), 'days', true)) : null;
  }

  get hasActiveTrial(): boolean {
    return this.trialExpiryMoment ? this.trialExpiryMoment > moment() : false;
  }

  get totalSocialSets(): number {
    return this.includedSocialSets + this.additionalSocialSets;
  }

  get totalUsers(): number {
    return this.includedUsers + this.additionalUsers;
  }

  get isCreator(): boolean {
    return this.bestDescribes === 'creator_influencer' || this.mainBusinessProfile?.businessModel === 'blog_influencer';
  }

  get linkinbioPagesCount(): number {
    const linkinbioPagesCounts = this.groups.map((group) => group.linkinbioPagesCount);

    return linkinbioPagesCounts.reduce((totalSum, count) => totalSum + count, 0);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    account: AccountModel;
  }
}
