import { computed } from '@ember/object';
import { equal, alias, reads } from '@ember/object/computed';
import { isNone, isBlank, isEmpty, isPresent } from '@ember/utils';
import Model, { attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { fabric } from 'fabric';
import { isEmpty as _isEmpty, forIn } from 'lodash';

import { findUnique } from 'later/utils/array-methods';
import { DEFAULT_PROCESSING_IMAGE_URL, DEFAULT_PROCESSING_STORY_URL } from 'later/utils/constants';
import Validations from 'later/validations/post-media-item';
import uuid from 'shared/utils/uuid';

import type { AsyncBelongsTo } from '@ember-data/model';
import type { CropArray } from 'editor/utils/canvas';
import type { Gradient, Pattern } from 'fabric/fabric-impl';
import type GramModel from 'later/models/gram';
import type MediaItemModel from 'later/models/media-item';
import type ProductTag from 'later/services/social/instagram-product-tagging';
import type { Maybe, EmptyObject } from 'shared/types';
import type {
  LaterFabricTextOptions,
  LaterFabricTextboxProperties,
  LaterFabricTransformation,
  LaterFabricTextboxes,
  LaterFabricObject,
  LaterFabricTextbox
} from 'shared/types/fabric';

const INSTAGRAM_STORY_VIDEO_DEFAULT_LENGTH = 15;
const INSTAGRAM_STORY_IMAGE_DEFAULT_LENGTH = 5;
const DEFAULT_TRIM_ARRAY = [0, null];

interface UserTag {
  username: string;
}

export default class PostMediaItemModel extends Model.extend(Validations) {
  @tracked previewDataUrl: Maybe<string> = null;
  @tracked processingImageUrl = DEFAULT_PROCESSING_IMAGE_URL;
  @tracked processingStoryUrl = DEFAULT_PROCESSING_STORY_URL;
  @tracked trimArray = DEFAULT_TRIM_ARRAY;

  @attr({ defaultValue: undefined }) declare altText?: string;
  @attr() declare cropArray: Maybe<CropArray>;
  @attr({ defaultValue: () => [] }) declare userTags: UserTag[] | null;
  @attr({ defaultValue: () => [] }) declare productTags: ProductTag[] | null;
  @attr('boolean', { defaultValue: false }) declare cropChange: boolean;
  @attr('boolean', { defaultValue: false }) declare readonly isRemoved: boolean;
  @attr('number', { defaultValue: 0 }) declare ordering: number;
  @attr('number', { defaultValue: 0 }) declare startTime: number; // seconds
  @attr('number', { defaultValue: 0 }) declare thumbOffset: number; // millisecond
  @attr('number', { defaultValue: 0 }) declare videoDurationMs: number;
  @attr('number') declare endTime: Maybe<number>; // seconds
  @attr('number') declare height: Maybe<number>;
  @attr('number') declare width: Maybe<number>;
  @attr('string') declare readonly highResUrl: Maybe<string>;
  @attr('string') declare readonly imageBucket: Maybe<string>;
  @attr('string') declare readonly imageKey: Maybe<string>;
  @attr('string') declare readonly imageUrl: Maybe<string>;
  @attr('string') declare readonly largeThumbnailUrl: Maybe<string>;
  @attr('string') declare readonly lowResUrl: Maybe<string>;
  @attr('string') declare readonly mediaType: Maybe<string>;
  @attr('string') declare readonly medResUrl: Maybe<string>;
  @attr('string') declare readonly medThumbnailUrl: Maybe<string>;
  @attr('string') declare readonly smallThumbnailUrl: Maybe<string>;
  @attr('string', { defaultValue: () => uuid() }) declare readonly tempId: string;
  @attr('string') declare readonly videoBucket: Maybe<string>;
  @attr('string') declare readonly videoKey: Maybe<string>;
  @attr('string') declare readonly videoUrl: Maybe<string>;
  @attr() declare transformation: Maybe<LaterFabricTransformation>;

  @belongsTo('gram', { async: true }) declare gram: AsyncBelongsTo<GramModel>;
  @belongsTo('mediaItem', { async: true }) declare mediaItem: AsyncBelongsTo<MediaItemModel>;

  @equal('mediaType', 'gif') declare isGif: boolean;
  @equal('mediaType', 'image') declare isImage: boolean;
  @equal('mediaType', 'video') declare isVideo: boolean;

  @reads('gram.autoPublish') declare isAutoPublish: boolean;
  @reads('gram.isInstagram') declare isInstagram: boolean;
  @reads('gram.isFacebook') declare isFacebook: boolean;
  @reads('gram.isPinterest') declare isPinterest: boolean;
  @reads('gram.isTwitter') declare isTwitter: boolean;
  @reads('gram.isTiktok') declare isTiktok: boolean;
  @reads('gram.isLinkedin') declare isLinkedin: boolean;
  @reads('gram.isNotificationPost') declare isNotificationPost: boolean;
  @reads('gram.isInstagramReel') declare isInstagramReel: boolean;
  @reads('gram.isFacebookReel') declare isFacebookReel: boolean;
  @reads('gram.isReel') declare isReel: boolean;
  @reads('gram.isYoutube') declare isYoutubeShort: boolean;

  @alias('mediaItem.sourceUsername') declare sourceUsername: Maybe<string>;

  @computed('height', 'width', 'cropArray.[]', 'mediaItem.aspectRatio')
  get aspectRatio(): Maybe<number> {
    if (!this.cropArray || this.cropArray.length === 0) {
      if ((!this.height && this.height !== 0) || (!this.width && this.width !== 0)) {
        return this.mediaItem.get('aspectRatio');
      }
      return this.width / this.height;
    }
    return (this.cropArray[2] - this.cropArray[0]) / (this.cropArray[3] - this.cropArray[1]);
  }

  get isInstagramFeedVideo(): boolean {
    return this.isVideo && !this.isInstagramReel;
  }

  get croppedHeight(): Maybe<number> {
    if (!this.cropArray || this.cropArray.length === 0) {
      return this.height;
    }
    return this.cropArray[3] - this.cropArray[1];
  }

  get croppedWidth(): Maybe<number> {
    if (!this.cropArray || this.cropArray.length === 0) {
      return this.width;
    }
    return this.cropArray[2] - this.cropArray[0];
  }

  @computed('mediaType')
  get mediaUrl(): Maybe<string> {
    if (this.isVideo) {
      return this.videoUrl;
    }
    return this.imageUrl;
  }

  get previewThumbnailUrl(): Maybe<string> {
    if (!isEmpty(this.previewDataUrl) && this.get('hasDirtyAttributes')) {
      return this.previewDataUrl;
    }
    return this.smallThumbnailUrl;
  }

  get previewUrl(): Maybe<string> {
    if (!isEmpty(this.previewDataUrl) && this.get('hasDirtyAttributes')) {
      return this.previewDataUrl;
    } else if (!isNone(this.id)) {
      if (!isNone(this.highResUrl)) {
        return this.highResUrl;
      } else if (!isNone(this.imageUrl)) {
        return this.imageUrl;
      }
      return this.processingUrl;
    }
    return this.mediaItem.get('largeDisplayUrl');
  }

  get processingUrl(): string {
    if (this.gram.get('isInstagramStory')) {
      return this.processingStoryUrl;
    }
    return this.processingImageUrl;
  }

  get sortableId(): string | undefined {
    if (!isNone(this.id)) {
      return this.id;
    }
    return this.mediaItem.get('id');
  }

  @computed('mediaType', 'videoDuration')
  get storySecondDuration(): Maybe<number> {
    if (this.isVideo) {
      if (isNone(this.videoDuration)) {
        return INSTAGRAM_STORY_VIDEO_DEFAULT_LENGTH;
      }
      return this.videoDuration;
    }
    return INSTAGRAM_STORY_IMAGE_DEFAULT_LENGTH;
  }

  get textboxes(): LaterFabricTextboxes {
    const transformationObjects = this.transformation?.serialized_editor_state?.objects;
    const textboxArray = this.#filterTextboxes(transformationObjects);
    const textboxes: LaterFabricTextboxes = {};

    if (isPresent(textboxArray)) {
      fabric.util.enlivenObjects(
        textboxArray,
        (textboxInstances: LaterFabricTextbox[]) => {
          textboxInstances.forEach((textbox) => {
            const id = uuid();
            textbox.set({ id });
            textboxes[id] = textbox;
          });
        },
        'fabric'
      );
    }

    return textboxes;
  }

  @computed('textboxes')
  get textboxProperties(): LaterFabricTextboxProperties | EmptyObject {
    if (_isEmpty(this.textboxes)) {
      return {};
    }

    const fontName: (string | undefined)[] = [];
    const fontColor: (string | Pattern | Gradient)[] = [];
    const alignment: (string | undefined)[] = [];
    const emphasis: (string | number | undefined)[] = [];
    const textHighlight: string[] = [];
    const photoText: (string | undefined)[] = [];

    forIn(this.textboxes, ({ fontFamily, textAlign, text, styles }) => {
      fontName.push(fontFamily);
      alignment.push(textAlign);
      photoText.push(text);
      forIn(styles, (textLine) => {
        forIn(
          textLine,
          ({ fill, fontStyle, fontWeight, textBackgroundColor, underline, uppercase }: LaterFabricTextOptions) => {
            if (fill) {
              fontColor.push(fill);
            }

            if (fontStyle) {
              emphasis.push(fontStyle);
            }

            if (fontWeight) {
              emphasis.push(fontWeight);
            }

            if (textBackgroundColor) {
              textHighlight.push(textBackgroundColor);
            }

            if (underline) {
              emphasis.push('underline');
            }

            if (uppercase) {
              emphasis.push('uppercase');
            }
          }
        );
      });
    });

    return {
      alignment: findUnique(alignment).join(),
      emphasis: isPresent(emphasis) ? findUnique(emphasis).join() : 'null_value',
      font_name: findUnique(fontName).join(),
      font_colour: findUnique(fontColor).join(),
      object_count: Object.keys(this.textboxes).length,
      text_highlight: isPresent(textHighlight) ? findUnique(textHighlight).join() : 'null_value',
      photo_text: photoText.join()
    };
  }

  get uniqueId(): string {
    if (isNone(this.id)) {
      return this.tempId;
    }
    return this.id;
  }

  @computed('mediaItem.{videoDuration,videoDurationMilliseconds}', 'startTime', 'endTime')
  get videoDuration(): number | undefined {
    const videoDurationMilliseconds = this.mediaItem.get('videoDurationMilliseconds');
    const videoDuration = this.mediaItem.get('videoDuration');

    let mediaDuration = videoDuration;
    if (videoDurationMilliseconds) {
      mediaDuration = videoDurationMilliseconds / 1000;
    }

    if (isBlank(this.endTime) && !this.startTime) {
      return mediaDuration ? mediaDuration : 0;
    } else if (this.endTime && !this.startTime) {
      return this.endTime;
    } else if (isNone(mediaDuration)) {
      return undefined;
    } else if (isNone(this.endTime) && this.startTime) {
      return mediaDuration - this.startTime;
    } else if (isNone(this.endTime) || !this.endTime) {
      return undefined;
    }

    return this.endTime - this.startTime;
  }

  get isCustomCover(): boolean {
    return this.isInstagramReel && this.ordering === 1;
  }

  #isTextbox(fabricObject: LaterFabricObject): fabricObject is LaterFabricTextbox {
    return fabricObject.type === 'textbox';
  }

  #filterTextboxes(fabricObjects: LaterFabricObject[] = []): LaterFabricTextbox[] {
    const textBoxes = fabricObjects.filter(this.#isTextbox);
    // Since Fredoka One is no longer available for download by Flareon, we need
    // to substitute it with Fredoka. This fix handles copied posts with text boxes
    // created prior to the removal of Fredoka One.
    textBoxes.forEach((textbox) => {
      if (textbox.fontFamily === 'Fredoka One') {
        textbox.fontFamily = 'Fredoka';
      }
    });
    return textBoxes;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'post-media-item': PostMediaItemModel;
  }
}
